import BandwagonDotCom from "../components/BandwagonDotCom"
import HeroBar from '../components/HeroBar'
import MobileMenu from "../components/MobileMenu"
import React from "react"

import { Link } from "gatsby"
import { useQueryParam, StringParam } from "use-query-params";

import './thanks.scss';

const Contact = () => {
    const [email] = useQueryParam("email", StringParam);

    return <BandwagonDotCom>
      <MobileMenu>
        <Link>Thanks!</Link>
        <Link className="hiring" to="https://bandwagon.breezy.hr/">We're&nbsp;Hiring!</Link>
        <Link to="/">Home</Link>
        <Link to="/contact">Contact</Link>
        <Link to="/team">Team</Link>
      </MobileMenu>
      <HeroBar />
      <div id="thanks-content">
          <h1>Hey, thanks for getting in touch!</h1>
          <p>
            We'll reach out to <strong>{email}</strong> just as soon as we can!
          </p>
      </div>
    </BandwagonDotCom>;
};

export default Contact;
